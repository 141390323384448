<template>
  <layout :activeIndex="activeIndex" :activeModel.sync="activeModel" :topBarItems="topBarItems" @setIndex="setIndex">
    <template #left-0>
      <left-menu :floors="floors" :system="system" @select="leftMenuSelect"></left-menu>
    </template>

    <template #left-1>
      <b-card>
        <b-card-title>筛选</b-card-title>
        <label>设备名称</label>
        <b-form-input placeholder="输入设备名称查询" trim></b-form-input>
      </b-card>
    </template>

    <template #right-1>

    </template>

  </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import {reactive, toRefs} from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import store from "@/store";

export default {
  name: "hidden-construction-work",
  components: {
    Layout,
    LeftMenu,
    panelGroup: () => import("@/views/panel-group"),
  },
  setup() {
    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: {path: "hidden-construction-work/hidden-construction-work/hidden-construction-work", isDetail: false, dbIds: ["equipment_"]},
        },
        {title: "设备列表"},
      ],
    });
    //左侧菜单相关参数
    const leftMenuParams = reactive({
      floors: [
        {
          title: "RF",
          value: 13,
          model: {
            path: "hidden-construction-work/hidden-construction-work_RF/hidden-construction-work_RF",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F12",
          value: 12,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F11/hidden-construction-work_F11",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F11",
          value: 11,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F10/hidden-construction-work_F10",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F10",
          value: 10,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F9/hidden-construction-work_F9",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F9",
          value: 9,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F8/hidden-construction-work_F8",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F8",
          value: 8,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F7/hidden-construction-work_F7",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F7",
          value: 7,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F6/hidden-construction-work_F6",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F6",
          value: 6,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F5/hidden-construction-work_F5",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F5",
          value: 5,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F4/hidden-construction-work_F4",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F3",
          value: 3,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F3/hidden-construction-work_F3",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F2",
          value: 2,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F2/hidden-construction-work_F2",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F1",
          value: 1,
          model: {
            path: "hidden-construction-work/hidden-construction-work_F1/hidden-construction-work_F1",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B1",
          value: -1,
          model: {
            path: "hidden-construction-work/hidden-construction-work_B1/hidden-construction-work_B1",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B2",
          value: -2,
          model: {
            path: "hidden-construction-work/hidden-construction-work_B2/hidden-construction-work_B2",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B3",
          value: -3,
          model: {
            path: "hidden-construction-work/hidden-construction-work_B3/hidden-construction-work_B3",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B5",
          value: -5,
          model: {
            path: "hidden-construction-work/hidden-construction-work_B5/hidden-construction-work_B5",
            dbIds: ["equipment_"],
          },
        },
      ],
      system: true,
    });
    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      console.log("leftMenuSelectEvent", event);
      // activeModel.value = event.model
      if (event?.equip_name) {
        event.model.isDetail = true;
        layoutParams.activeModel = event.model;
      } else {
        event.model.isDetail = false;
        layoutParams.activeModel = event.model;
      }
    };
    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };
    const data = reactive({});


    //设备列表跳转到模型大样图
    const equip_to_detail_model = (item) => {
      setIndex(0)
      setTimeout(() => {
        store.commit("jsf35/setEquipSelected", item);
        leftMenuSelect(item)
      }, 1000)
    }
    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      leftMenuSelect,
      setIndex,
      ...toRefs(data),
      equip_to_detail_model
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
